/* ===================== TAB ======================*/

.tab-section {
  /* background-color: #f3f4f6; */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 80px;
}

.left {
  margin-top: 90px;
  margin-bottom: 75px;
}

.left-image {
	border-radius: 20px;
}

.rigth {
  margin-top: 20px;
  margin-bottom: 75px;
}

.btn-center {
  text-align: center;
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.br-5 {
  border-radius: 5px !important;
}

.footer-tab {
  background-color: #e5e7eb;
  background-image: linear-gradient(#d1d5db,rgba(209,213,219,0) 13%);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: center;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  display: flex;
}

.contain {
  background-color: #f3f4f6;
}

.title {
  color: #111827;
  margin-top: 0;
  padding-right: 10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  position: static;
  margin-bottom: 20px;
}

.icon {
  color: #6706ce !important;
  margin-top: 10px;
  font-size: 30px;
}

.text {
  line-height: 1.6;
  margin-left: 10px;
}

.link-item {
  align-self: flex-end;
  text-align: end;
}

.link-a {
	font-size: 15px;
	color: #6706ce !important;
  background-color: transparent;
  font-weight: 700;
  text-decoration: none;
}

.link-a:hover {
  color: rgba(103,6,206,.8) !important;
  text-decoration: underline;
}

.btn-demo {
  color: #fff;
  text-align: center;
  background-color: #6706ce;
  border: 1px solid #6706ce;
  border-radius: 4px;
  padding: 13px 20px;
  line-height: 1;
  text-decoration: none;
  font-weight: 700;
}

.btn-demo:hover {
  color: #fff;
  background-color: rgba(103,6,206,.8) !important;
  text-decoration: none;
}

@media only screen and (max-width: 765px) {
  .rigth {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .left {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
/* ========== Custom css to tab ================= */

@media only screen and (max-width: 950px) {
  .MuiPaper-root{
    padding: 0 !important;
  }
}

@media only screen and (max-width: 765px) {
  .MuiTabs-flexContainer {
    display: grid !important;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiPaper-root {
    background-color: #f3f4f6 !important;
    box-shadow: none !important;
    padding: 0px 65px 0px 65px !important;
  }
}

.MuiTabs-flexContainer{
  background-color: #f3f4f6 !important;
  padding: 0px 65px 0px 65px !important;
}

.MuiPaper-root {
  background-color: #f3f4f6 !important;
  box-shadow: none !important;
  padding: 0px 60px 0px 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.MuiTabs-root {
  margin-top: 20px;
}

.MuiTab-iconWrapper {
  position: absolute;
  left: 0;
}

.MuiButtonBase-root {
  color: black !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.MuiTab-root {
  font-weight: 700 !important;
  font-family: Inter, sans-serif !important;
}

.Mui-selected {
  color: #6706ce !important;
}

.MuiButtonBase-root:hover {
  color: #6706ce !important;
}


.MuiTabs-indicator {
  background-color: #6706ce !important;
}

